.App {
  text-align: center;
  overflow-x: hidden;
}
.corBotao {
  color: white;
  background-color: #3c788c;
}
.App-logo {
  height: 4vmin;
  pointer-events: none;
}
.MuiTableCell-root{
  cursor: pointer;
}
.MuiTableCell-root:hover {
  background-color: #dedede;
}