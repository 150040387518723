.logo-notfound {
    height: 60vmin;
    pointer-events: none;
}

.NotFound-header {
    background-color: rgb(252,231,228);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
}